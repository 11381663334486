var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":_vm.$t('config.tables.edit_tables'),"no-close-on-backdrop":"","size":"xl"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',[_c('validation-observer',{ref:"validation"},[_c('b-row',_vm._l((_vm.tables),function(item,index){return _c('b-col',{key:item.id,attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-card',[_c('b-form-group',{staticClass:"my-0"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.remove(index)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1)],1),_c('b-form-group',{attrs:{"label":_vm.$t('form_data.name') + ' ' + (index + 1)}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.name') + ' ' + (index + 1),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('config.tables.shape_type') + ' ' + (index + 1)}},[_c('validation-provider',{attrs:{"name":_vm.$t('config.tables.shape_type') + ' ' + (index + 1),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"appendToBody":"","label":"name","options":_vm.types,"reduce":function (type) { return type.id; },"clearable":false},model:{value:(item.shape_type),callback:function ($$v) {_vm.$set(item, "shape_type", $$v)},expression:"item.shape_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('table_fields.hidden_table') + ' ' + (index + 1)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{model:{value:(item.hidden),callback:function ($$v) {_vm.$set(item, "hidden", $$v)},expression:"item.hidden"}},[_vm._v(" "+_vm._s(_vm.$t('table_fields.hidden_table')))]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('colorSelector',{attrs:{"product-name":_vm.$t('general.color'),"displayBlock":true,"showPreview":false},model:{value:(item.color),callback:function ($$v) {_vm.$set(item, "color", $$v)},expression:"item.color"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)}),1)],1)],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('b-row',[_c('b-col',[_c('b-form-group',[_c('b-button',{staticClass:"float-left",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.tables.push({id: _vm.id++, name: '', shape_type: 1, x: 0, y: 0, x_size: 1000, y_size: 1000, r: 1000, chairs: [], color: '#3E3E3E'})}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('general.add')))])],1)],1)],1),_c('b-col',[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":_vm.validate}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('config.tables.edit_tables')))])],1)],1)],1)],1)]},proxy:true}]),model:{value:(_vm.modalActive),callback:function ($$v) {_vm.modalActive=$$v},expression:"modalActive"}})}
var staticRenderFns = []

export { render, staticRenderFns }